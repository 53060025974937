import React, { useEffect, useState } from "react";
import addNotification from "react-push-notification";
import { RiCopperCoinFill } from "react-icons/ri";
import BettingButtons from "../betting/BettingButtons";
import BettingModel from "../models/BettingModel";
import ResultHeader from "../headerComponent/ResultHeader";
import { getTimer, getTimerFive, getTimerOne } from "../../lib/api/timer";
import { getUser } from "../../lib/api/user";
import {
  getBettingFiveMinutesTable,
  getBettingOneMinuteTable,
  getBettingTable
} from "../../lib/api/betting";
import {
  getBettingFiveMinutesTableColor,
  getBettingOneMinuteTableColor,
  getBettingTableColor
} from "../../lib/api/bettingColor";
import {
  getUserCurrentBettingTable,
  postUserBetting
} from "../../lib/api/userBetting";
import { useAuth } from "../../context/AuthContext";
import BettingButtonsColor from "../betting/BettingButtonsColor";
import NumberFormat from "../../utilities/NumberFormat";

function DashboardHeader({
  page,
  pageColor,
  setDashBoardTable,
  setDashBoardTableColor,
  setUserBettingHistory,
  userDetail,
  setUserDetail,
  tab,
  setTab,
  setScreen,
  topTab,
  setTopTab,
  loading,
  setLoading
}) {
  const { authToken, userId } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMsgVisible, setIsMsgVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [find, setFinder] = useState(0);
  const [betFind, setBetFind] = useState("");
  const [lastItem, setLastItem] = useState(undefined);
  const [lastItemId, setLastItemId] = useState(undefined);
  const [serverTimer, setServerTimer] = useState(null);
  const [serverLoading, setServerLoading] = useState(null);

  const [lastItemColor, setLastItemColor] = useState(undefined);
  const [lastItemIdColor, setLastItemIdColor] = useState(undefined);

  const buttonClick = () => {
    addNotification({
      title: "Warning",
      subtitle: "This is a subtitle",
      message: "This is a very long message",
      theme: "darkblue",
      native: true // when using native, your OS will handle theming.
    });
  };

  const getUserDetail = () => {
    getUser(authToken, userId)
      .then((res) => {
        setUserDetail(res);
      })
      // .catch((e) => console.log(e));
  };

  const getUserBettingTableDetails = () => {
    getUserCurrentBettingTable(
      authToken,
      userId,
      tab,
      topTab === "Three" ? 3 : topTab === "One" ? 1 : 5
    )
      .then((res) => {
        setUserBettingHistory(res);
      })
      // .catch((e) => console.log(e));
  };

  const getTimerDetail = () => {
    setLoading(true);
    getTimer(authToken)
      .then((res) => {
        setServerTimer(res.timer);
        setServerLoading(null);
        setIsDisabled(false);
        setLoading(false);
      })
      // .catch((e) => console.log(e));
  };

  const getBettingTableDetails = (pageNo, pageSize) => {
    getBettingTable(authToken, pageNo, pageSize)
      .then((res) => {
        setDashBoardTable(res);
        setLastItem(res?.lastBettingItem?.id);
        setLastItemId(res?.lastBettingItem?.bettingItemID);
      })
      // .catch((e) => console.log(e));
  };

  const getBettingTableDetailsColor = (pageNo, pageSize) => {
    getBettingTableColor(authToken, pageNo, pageSize)
      .then((res) => {
        setDashBoardTableColor(res);
        setLastItemColor(res?.lastBettingItem?.id);
        setLastItemIdColor(res?.lastBettingItem?.bettingItemID);
      })
      // .catch((e) => console.log(e));
  };

  const getTimerOneDetail = () => {
    setLoading(true);
    getTimerOne(authToken)
      .then((res) => {
        setServerTimer(res.timer);
        setServerLoading(null);
        setIsDisabled(false);
        setLoading(false);
      })
      // .catch((e) => console.log(e));
  };

  const getBettingOneTableDetails = (pageNo, pageSize) => {
    getBettingOneMinuteTable(authToken, pageNo, pageSize)
      .then((res) => {
        setDashBoardTable(res);
        setLastItem(res?.lastBettingItem?.id);
        setLastItemId(res?.lastBettingItem?.bettingItemID);
      })
      // .catch((e) => console.log(e));
  };

  const getBettingOneTableDetailsColor = (pageNo, pageSize) => {
    getBettingOneMinuteTableColor(authToken, pageNo, pageSize)
      .then((res) => {
        setDashBoardTableColor(res);
        setLastItemColor(res?.lastBettingItem?.id);
        setLastItemIdColor(res?.lastBettingItem?.bettingItemID);
      })
      // .catch((e) => console.log(e));
  };

  const getTimerFiveDetail = () => {
    setLoading(true);
    getTimerFive(authToken)
      .then((res) => {
        setServerTimer(res.timer);
        setServerLoading(null);
        setIsDisabled(false);
        setLoading(false);
      })
      // .catch((e) => console.log(e));
  };

  const getBettingFiveTableDetails = (pageNo, pageSize) => {
    getBettingFiveMinutesTable(authToken, pageNo, pageSize)
      .then((res) => {
        setDashBoardTable(res);
        setLastItem(res?.lastBettingItem?.id);
        setLastItemId(res?.lastBettingItem?.bettingItemID);
      })
      // .catch((e) => console.log(e));
  };

  const getBettingFiveTableDetailsColor = (pageNo, pageSize) => {
    getBettingFiveMinutesTableColor(authToken, pageNo, pageSize)
      .then((res) => {
        setDashBoardTableColor(res);
        setLastItemColor(res?.lastBettingItem?.id);
        setLastItemIdColor(res?.lastBettingItem?.bettingItemID);
      })
      // .catch((e) => console.log(e));
  };

  useEffect(() => {
    getUserDetail();
    getUserBettingTableDetails();
    if (topTab === "One") {
      getTimerOneDetail();
      getBettingOneTableDetails(1, 10);
      getBettingOneTableDetailsColor(1, 10);
    } else if (topTab === "Three") {
      getTimerDetail();
      getBettingTableDetails(1, 10);
      getBettingTableDetailsColor(1, 10);
    } else if (topTab === "Five") {
      getTimerFiveDetail();
      getBettingFiveTableDetails(1, 10);
      getBettingFiveTableDetailsColor(1, 10);
    }
  }, []);

  useEffect(() => {
    getUserDetail();
    getUserBettingTableDetails();
    if (topTab === "One") {
      getTimerOneDetail();
      getBettingOneTableDetails(1, 10);
      getBettingOneTableDetailsColor(1, 10);
    } else if (topTab === "Three") {
      getTimerDetail();
      getBettingTableDetails(1, 10);
      getBettingTableDetailsColor(1, 10);
    } else if (topTab === "Five") {
      getTimerFiveDetail();
      getBettingFiveTableDetails(1, 10);
      getBettingFiveTableDetailsColor(1, 10);
    }
  }, [tab, topTab]);

  useEffect(() => {
    // getBettingTableDetails(page, 10);
    if (topTab === "One") {
      getBettingOneTableDetails(page, 10);
    } else if (topTab === "Three") {
      getBettingTableDetails(page, 10);
    } else if (topTab === "Five") {
      getBettingFiveTableDetails(page, 10);
    }
  }, [page, topTab]);

  useEffect(() => {
    // getBettingTableDetailsColor(pageColor, 10);
    if (topTab === "One") {
      getBettingOneTableDetailsColor(pageColor, 10);
    } else if (topTab === "Three") {
      getBettingTableDetailsColor(pageColor, 10);
    } else if (topTab === "Five") {
      getBettingFiveTableDetailsColor(pageColor, 10);
    }
  }, [pageColor, topTab]);

  useEffect(() => {
    if (serverLoading) {
      getUserDetail();
      getUserBettingTableDetails();
      if (topTab === "One") {
        getTimerOneDetail();
        getBettingOneTableDetails(page, 10);
        getBettingOneTableDetailsColor(pageColor, 10);
      } else if (topTab === "Three") {
        getTimerDetail();
        getBettingTableDetails(page, 10);
        getBettingTableDetailsColor(pageColor, 10);
      } else if (topTab === "Five") {
        getTimerFiveDetail();
        getBettingFiveTableDetails(page, 10);
        getBettingFiveTableDetailsColor(pageColor, 10);
      }
    }
  }, [serverLoading, topTab]);

  const showModal = (bettingFind) => {
    if (isDisabled && userId !== "1") {
      setIsModalVisible(false);
      setFinder(0);
      setBetFind("");
    } else {
      setIsModalVisible(true);
      setBetFind(bettingFind);
    }
  };

  useEffect(() => {
    if (isDisabled && userId !== "1") {
      setIsModalVisible(false);
      setFinder(0);
      setBetFind("");
    }
  }, [isDisabled, userId]);

  //Model Betting Hit Button
  const handleOk = (
    checkBox,
    radio,
    setCheckBox,
    setRadio,
    setState,
    setError
  ) => {
    let amount =
      userDetail?.totalCredit === 0 ||
      userDetail?.wallet === 0 ||
      userDetail?.wallet < 10
        ? userDetail?.bonus
        : userDetail?.wallet;
    let amountType =
      userDetail?.totalCredit === 0 ||
      userDetail?.wallet === 0 ||
      userDetail?.wallet < 10
        ? "Bonus"
        : "Wallet";
    if (radio === 1) {
      setError("select the amount");
      setIsMsgVisible(true);
    } else if (!checkBox) {
      setError("Agree to hit the amount");
      setIsMsgVisible(true);
    } else if (find > amount) {
      setError(`You have only ${amount} rupees`);
    } else if (checkBox && radio !== 1) {
      let data = {
        amount: find,
        amountType: amountType,
        eventHit:
          betFind === "odd" ||
          betFind === "even" ||
          betFind === "green" ||
          betFind === "red"
            ? betFind
            : "",
        numberHit:
          betFind === "odd" ||
          betFind === "even" ||
          betFind === "green" ||
          betFind === "red"
            ? 0
            : betFind,
        userId: userId,
        bettingItemTableId: tab === "dice" ? lastItem : 0,
        bettingColorItemTableId: tab === "dice" ? 0 : lastItemColor,
        bettingItemTableDataBaseId:
          tab === "dice" ? lastItemId : lastItemIdColor,
        bettingItemTableType: tab === "dice" ? "dice" : "color",
        bettingItemType: topTab === "Three" ? 3 : topTab === "One" ? 1 : 5,
        reference: userDetail?.reference,
        event_odd: betFind === "odd" ? find : "",
        event_even: betFind === "even" ? find : "",
        event_green: betFind === "green" ? find : "",
        event_red: betFind === "red" ? find : "",
        one: betFind === 1 ? find : 0,
        two: betFind === 2 ? find : 0,
        three: betFind === 3 ? find : 0,
        four: betFind === 4 ? find : 0,
        five: betFind === 5 ? find : 0,
        six: betFind === 6 ? find : 0,
        zero: betFind === 0 ? find : 0,
        seven: betFind === 7 ? find : 0,
        eight: betFind === 8 ? find : 0,
        nine: betFind === 9 ? find : 0
      };
      insertBetting(data);
      // console.log(data);
      setIsModalVisible(false);
      setCheckBox(false);
      setRadio(1);
      setState(1);
      setError("");
      setIsMsgVisible(true);
    }
  };

  const insertBetting = async (data) => {
    await postUserBetting(authToken, data);
    getUserDetail();
    getUserBettingTableDetails();
  };

  return (
    <div>
      <div className="bg-red-700 mx-5 my-3 rounded-md">
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col space-y-3 py-3 items-start justify-start">
              {/* <div className="flex items-center">
                <div className="flex flex-shrink-0 items-center">
                  <img className="h-8 w-12 ml-2 md:ml-5" src={dark} alt="" />
                  <p className="font-medium text-xl text-black ml-1">WinBbuzz</p>
                </div>
              </div> */}

              <div className="flex items-center justify-center">
                <p className="font-medium text-xl text-white ml-1">
                  Available Balance :
                </p>
                {userDetail?.totalCredit === 0 ||
                userDetail?.wallet === 0 ||
                userDetail?.wallet < 10 ? (
                  <div
                    className={`flex text-xl pl-1
                        ${
                          userDetail?.bonus <= 15
                            ? "text-white font-bold animate-bounce"
                            : "text-white font-medium"
                        } items-center`}
                    onClick={() => setScreen("Wallet")}
                  >
                    <RiCopperCoinFill className="text-white" />
                    <p className="pl-px">
                      {userDetail
                        ? NumberFormat.bonusFormat(userDetail?.bonus)
                        : "Loading..."}
                    </p>
                  </div>
                ) : (
                  <div
                    className={`flex text-xl ${
                      userDetail?.wallet <= 15
                        ? "text-white font-bold animate-bounce"
                        : "text-white font-medium"
                    } items-center`}
                    onClick={() => setScreen("Wallet")}
                  >
                    <p className="pl-px">
                      {userDetail
                        ? NumberFormat.currencyFormat(userDetail?.wallet)
                        : "Loading..."}
                    </p>
                  </div>
                )}
              </div>

              <div className="space-x-3">
                <button
                  className="bg-green-700 text-white font-medium px-2 py-1 rounded-md"
                  onClick={() => setScreen("Wallet")}
                >
                  Recharge
                </button>
                <button
                  className="bg-white text-red-700 font-medium px-2 w-32 py-1 rounded-md"
                  onClick={() => setTab(tab === "dice" ? "color" : "dice")}
                >
                  Switch {tab === "dice" ? "Color" : "Dice"}
                </button>
              </div>
              {/* <div className="-mr-2 flex md:hidden"> 
                  <Disclosure.Button className="inline-flex
                   items-center justify-center rounded-md 
                   bg-white p-2 text-gray-700 
                   hover:bg-gray-700 hover:text-white
                    focus:outline-none focus:ring-2
                     focus:ring-white focus:ring-offset-2
                      focus:ring-offset-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <RiCloseFill
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <HiMenu
                        className={`block h-6 w-6 ${userDetail?.wallet <= 40 ? "animate-bounce" : ""
                          }`}
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div> */}
            </div>
          </div>

          {/* <Disclosure.Panel className="md:hidden">
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img className="h-12 w-12 rounded-full" src={dp} alt="" />
                  </div>
                  <div className="ml-3 space-y-1">
                    <div className="text-base font-medium leading-none text-gray-800">
                      Member-{userDetail?.username}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-800">
                      {userDetail?.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <IoIosNotificationsOutline
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <Disclosure.Button
                    onClick={() => removeAuthToken()}
                    className="rounded-md cursor-pointer
                    px-3 py-2 text-base font-medium space-x-2
                     text-gray-800 hover:bg-gray-700 flex items-center
                      hover:text-white"
                  >
                    <p>
                      LogOut
                    </p>
                    <MdLogin size={20} />
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel> */}
        </>
      </div>

      <div className="flex-1 border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-1 flex-wrap -mb-px text-center text-gray-500 dark:text-gray-400 text-lg">
          <li className="me-2 flex-1 ">
            <div
              className={`inline-flex items-center justify-center p-4 border-b-2 
              border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group
              ${
                topTab === "One"
                  ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500 font-medium"
                  : ""
              }`}
              onClick={() => setTopTab("One")}
            >
              Fast
            </div>
          </li>
          <li className="me-2 flex-1 ">
            <div
              className={`inline-flex items-center justify-center p-4 border-b-2 
                border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group
                ${
                  topTab === "Three"
                    ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500 font-medium"
                    : ""
                }`}
              onClick={() => setTopTab("Three")}
            >
              Standard
            </div>
          </li>
          <li className="me-2 flex-1 ">
            <div
              className={`inline-flex items-center justify-center p-4 border-b-2 
                border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group
                ${
                  topTab === "Five"
                    ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500 font-medium"
                    : ""
                }`}
              onClick={() => setTopTab("Five")}
            >
              Think
            </div>
          </li>
        </ul>
      </div>
      {loading ? (
        <>
          <div class="flex-1 rounded-md p-4 mx-auto">
            <div class="animate-pulse flex space-x-4 flex-1">
              <div class="flex-1 space-y-6 py-1">
                <div class="h-2 bg-slate-700 rounded"></div>
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                    <div class="h-2 bg-slate-700 rounded col-span-2"></div>
                    <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div class="h-2 bg-slate-700 rounded"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {userDetail?.bonus <= 10 && userDetail?.wallet <= 10 && (
              <a
                href={"/profile"}
                className="moving-text-container flex md:hidden"
              >
                <div>
                  <span className="moving-text text-base font-medium text-red-700">
                    Your wallet is low. Please add amount.
                  </span>
                </div>
              </a>
            )}
          </div>
          <ResultHeader
            serverTimer={serverTimer}
            isDisabled={isDisabled}
            setServerLoading={setServerLoading}
            setIsDisabled={setIsDisabled}
            userDetail={userDetail}
            lastItemId={tab === "dice" ? lastItemId : lastItemIdColor}
          />
          {tab === "dice" ? (
            <BettingButtons
              // isDisabled={userId === "1" ? false : isDisabled}
              isDisabled={isDisabled}
              showModal={showModal}
            />
          ) : (
            <BettingButtonsColor
              // isDisabled={userId === "1" ? false : isDisabled}
              isDisabled={isDisabled}
              showModal={showModal}
            />
          )}
          <BettingModel
            isModalVisible={isModalVisible}
            // isDisabled={userId === "1" ? false : isDisabled}
            isDisabled={isDisabled}
            betFind={betFind}
            setIsModalVisible={setIsModalVisible}
            setFinder={setFinder}
            handleOk={handleOk}
            setBetFind={setBetFind}
          />
        </>
        // <>
        //   <div class="flex-1 rounded-md p-4 mx-auto">
        //     <div class="animate-pulse flex space-x-4 flex-1">
        //       <div class="flex-1 space-y-6 py-1">
        //         <div class="h-2 bg-slate-700 rounded"></div>
        //         <div class="space-y-3">
        //           <div class="grid grid-cols-3 gap-4">
        //             <div class="h-2 bg-slate-700 rounded col-span-2"></div>
        //             <div class="h-2 bg-slate-700 rounded col-span-1"></div>
        //           </div>
        //           <div class="h-2 bg-slate-700 rounded"></div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
      )}
    </div>
  );
}

export default DashboardHeader;
